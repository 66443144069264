import React, { useEffect, useRef } from 'react';
import { models, service, factories } from 'powerbi-client';
import './PowerBIReportEmbedded.scss';
import { config } from '../../shared/config';

const PowerBIReportEmbedded = function() {
  const reportElementRef = useRef<HTMLDivElement>(null);

  const getEmbeddedInfo = async () => {
    const response = await fetch(`${config.get('apiRoot')}api/v1/PowerBIReportEmbedded/GetEmbedInfo`);
    const info = await response.json();
    return {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: info.EmbedToken.Token,
      // You can embed different reports as per your need
      embedUrl: info.EmbedReport[0].EmbedUrl

      // Enable this setting to remove gray shoulders from embedded report
      // settings: {
      //     background: models.BackgroundType.Transparent
      // }
    };
  };

  useEffect(() => {
    getEmbeddedInfo().then(info => {
      const powerBi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
      const report = powerBi.embed(reportElementRef.current as HTMLElement, info);
      report.on('error', function(event) {
        const errorMsg = event.detail;

        // Use errorMsg variable to log error in any destination of choice
        console.error(errorMsg);
        return;
      });
    });
  }, []);

  return (
    <div ref={reportElementRef} id="powerbi-report" className="responsive-iframe">
      PowerBI report will load here
    </div>
  );
};

export default PowerBIReportEmbedded;
