import React from 'react';
import TileGrid from '../tiles/tilegrid/TileGrid';
import HeroWelcome from '../herowelcome/HeroWelcome';
import ServiceTicketModal from '../service-ticket/ServiceTicketModal';
import PowerBIReportEmbedded from '../powerbireportembedded/PowerBIReportEmbedded';
import './Home.scss';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../shared/PrivateRoute';

const Home = () => {
  const routeMatch = useRouteMatch();
  return (
    <>
      <div className="home">
        <HeroWelcome />
      </div>
      <TileGrid />
      <Switch>
        <PrivateRoute path={`${routeMatch.path}contact`} render={() => <ServiceTicketModal />} />
      </Switch>
    </>
  );
};

export default Home;
