import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/shared/Layout';
import Home from './components/home/Home';
import { config } from './shared/config';
import { TokenService } from './shared/auth';
import { Loader } from './components/shared/Loader';
import { UserContext, UserContextData } from './shared/UserContext';
import { LangContext } from './shared/LangContext';
import { ServiceBar } from './shared/layout/ServiceBar';
import PowerBIReportEmbedded from './components/powerbireportembedded/PowerBIReportEmbedded';
import { BrowserRouter } from 'react-router-dom';

const appDefaultLanguages = ['de', 'en', 'fr', 'pt'];
const appDefaultLanguage = 'en';

const App = () => {
  const checkIsSigningRedirectParams = () => {
    const keys = ['code', 'scope', 'state', 'session_state'];
    const parts = window.location.hash.split('&');
    parts[0] = parts[0].replace('#', '');
    return parts.every(part => {
      const key = part.split('=')[0];
      return keys.some(k => k === key);
    });
  };
  const [isSignInRedirect, setIsSignInRedirect] = useState<boolean>(true);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserContextData | null>(null);
  useEffect(() => {
    const signinRedirect = checkIsSigningRedirectParams();
    setIsSignInRedirect(signinRedirect);
  }, []);

  useEffect(() => {
    const tokenService = new TokenService();
    tokenService.getUser().then(user => {
      setIsUserLoading(false);
      if (user !== null && user.profile !== null) {
        const userName = user.profile.given_name || null;
        const userCompany = user.profile.organization_name || null;
        const userEmail = user.profile.email || null;
        const userAvatar = user.profile.picture || null;
        setUserData({
          userCompany,
          userEmail,
          userName,
          userAvatar
        });
      }
    });
  }, []);

  return (
    <>
      <div className="servicebar--placeholder">
        <ServiceBar
          clientId={config.get('clientId') as string}
          scope={config.get('clientScope') as string}
          settingsUri={config.get('serviceBarSettingsUri') as string}
          defaultLanguages={appDefaultLanguages}
          defaultLanguage={appDefaultLanguage}
        />
      </div>
      {isSignInRedirect || isUserLoading ? (
        <Loader />
      ) : (
        <Layout>
          <LangContext.Provider value={{ defaultLanguage: appDefaultLanguage, defaultLanguages: appDefaultLanguages }}>
            <UserContext.Provider value={userData}>
              <Switch>
                <Route path="/reports" component={PowerBIReportEmbedded} />
                <Route path="/" component={Home} />
              </Switch>
            </UserContext.Provider>
          </LangContext.Provider>
        </Layout>
      )}
    </>
  );
};

export default App;
